<template>
  <div>
    <div class="dis align_center">
      <div class="icon"></div>
      <div class="service" v-if="correct">修改服务活动月报</div>
      <div class="service" v-else>添加服务活动月报</div>
    </div>
    <el-form
      :model="ruleForm"
      status-icon
      ref="ruleForm"
      label-width="100px"
      :label-position="labelPosition"
    >
      <el-form-item label="填报人" prop="name" required>
        <el-input
          class="width_500"
          type="text"
          placeholder="请输入填报人姓名"
          v-model="ruleForm.name"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone" required>
        <el-input
          class="width_500"
          type="text"
          placeholder="请输入联系电话"
          v-model="ruleForm.phone"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="报送月份" required>
        <el-date-picker
          v-model="dataForm.time"
          type="month"
          placeholder="选择日期"
          value-format="yyyy-MM"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="添加指标" prop="target" required>
        <el-button
          type="primary"
          style="background-color: #ff6618; border-color: #ff6618"
          @click="addfirm()"
          >添加指标类型</el-button
        >
      </el-form-item>

      <div
        style="
          width: 736px;
          height: 177px;
          background: #fafafa;
          border-radius: 5px;
          padding: 10px;
        "
        v-for="(itme, index) in ruleForm.target"
        :key="index"
      >
        <div
          style="
            width: 60px;
            height: 20px;
            font-size: 18px;
            font-family: Source Han Sans SC Regular,
              Source Han Sans SC Regular-Regular;
            font-weight: 400;
            text-align: left;
            color: #333333;
            line-height: 20px;
            margin-bottom: 10px;
          "
        >
          指标{{ index + 1 }}
        </div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="指标类型"
              :prop="'target.' + index + '.pro_cate_id'"
            >
              <el-select
                v-model="ruleForm.target[index].pro_cate_id"
                placeholder=""
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="服务活动"
              :prop="'target.' + index + '.activity_num'"
            >
              <el-input
                type="text"
                v-model="ruleForm.target[index].activity_num"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="服务企业数" prop="enterprise_num">
              <el-input
                type="text"
                placeholder="请输入"
                v-model="ruleForm.target[index].enterprise_num"
              />
              <!-- <el-select v-model="" placeholder="">
                <el-option v-for="" :key=""></el-option>
              </el-select> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="服务人数" prop="people_num">
              <el-input
                type="text"
                placeholder="请输入"
                v-model="ruleForm.target[index].people_num"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <el-button
      type="primary"
      @click="corrects()"
      v-if="correct"
      style="background-color: #ff6618; border-color: #ff6618"
      >确定修改</el-button
    >
    <el-button
      v-else
      type="primary"
      @click="add()"
      style="background-color: #ff6618; border-color: #ff6618"
      >确定添加</el-button
    >
    <el-button
      type="primary"
      @click="back()"
      style="background-color: #cfcfcf; border-color: #cfcfcf"
      >取消</el-button
    >
  </div>
</template>

<script >
export default {
  data() {
    return {
      correct: "",
      labelPosition: "left",
      monthly: "",
      year: "",
      dataForm: {
        time: "",
      },
      options: [],
      ruleForm: {
        name: "",
        phone: "",
        // value: "",
        target: [
          {
            pro_cate_id: "", //公共产品分类ID
            activity_num: "", //活动场次数量
            enterprise_num: "", //服务企业数
            people_num: "", //服务人数
          },
        ],
      },
    };
  },
  created() {
    if(this.$route.query.ID){
      this.correct = this.$route.query.ID;
    }
   
    this.getcatelist();
  },
  methods: {
    //点击添加新增列表
    addfirm() {
      console.log("5464");
      this.ruleForm.target.push({
        pro_cate_id: "",
        activity_num: "",
        enterprise_num: "",
        people_num: "",
      });
    },
    //返回
    back() {
      this.$router.push("/serviceAction");
    },
    //下拉内容
    getcatelist() {
      let data = { token: localStorage.eleToken };
      this.$get("cate_list", data).then((res) => {
        this.options = res.result.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        console.log(this.options, "this.options");
      });
    },
    add() {
      let data = {
        token: localStorage.eleToken,
        name: this.ruleForm.name,
        mobile: this.ruleForm.phone,
        submit_time: this.dataForm.time,
        type: 2,
        target: this.ruleForm.target,
      };
      console.log(data);
      this.$post("/monthly_add", data).then((res) => {
        console.log(res, "res");
        this.$message({
          message: res.msg,
          type: "success",
          duration: 1500,
        });
        this.$router.push("/serviceAction");
      });
    },
    corrects() {
      let data = {
        token: localStorage.eleToken,
        name: this.ruleForm.name,
        mobile: this.ruleForm.phone,
        submit_time: this.dataForm.time,
        id: this.correct,
        type: 2,
        target: this.ruleForm.target,
      };
      console.log(data);
      this.$post("/monthly_upd", data).then((res) => {
        console.log(res, "res");
        this.$message({
          message: res.msg,
          type: "success",
          duration: 1500,
        });
        this.$router.push("/serviceAction");
      });
    },
  },
};
</script>
<style scoped>
.icon {
  width: 4px;
  height: 19px;
  background: #fb1010;
  border-radius: 2px;
}
.service {
  height: 30px;
  font-size: 24px;
  font-family: Source Han Sans SC Medium, Source Han Sans SC Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 30px;
  margin-left: 10px;
}
.width_500 {
  width: 500px;
}

.targetinput {
  width: 162px;
  height: 40px;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
}
</style>